import { inject, Injectable } from '@angular/core';
import { State, Action, Selector, StateContext, Store } from '@ngxs/store';
import { IApplicantInfo, IApplicationStatus, IVisaApplicantParams } from '../../models/applicant.interface';
import {
  FetchVisaApplicantForms,
  GetApplicationStatuses,
  GetApplicationStatusesError,
  GetApplicationStatusesSuccess,
  NextPage,
  PreviousPage,
  SetPageSize,
  UpdateQueryParams,
} from './applicants.actions';
import { ApplicantService } from '../../services/applicant/applicant.service';
import { catchError, finalize, first, of, tap } from 'rxjs';
import { API_REQUEST_PAGE_SIZE } from '@shared/constants';
import { StartLoading, StopLoading } from '@core/store';
import { HttpErrorResponse } from '@angular/common/http';

export interface ApplicantsStateModel {
  queryParams: IVisaApplicantParams;
  data: IApplicantInfo[];
  pageCount: number;
  totalRecordCount: number;
  loading: boolean;
  error: string | null;
  hasMoreData: boolean;
  applicationStatuses: IApplicationStatus[];
  isLoadingApplicationStatuses: boolean;
  isGetApplicationStatusesError: string | null;
}

@State<ApplicantsStateModel>({
  name: 'applicants',
  defaults: {
    data: [],
    queryParams: {
      page: 1,
      pageSize: API_REQUEST_PAGE_SIZE,
    },
    pageCount: 0,
    totalRecordCount: 0,
    loading: false,
    error: null,
    hasMoreData: false,
    applicationStatuses: [],
    isLoadingApplicationStatuses: false,
    isGetApplicationStatusesError: null,
  },
})
@Injectable()
export class ApplicantsState {
  private applicantService = inject(ApplicantService);
  private store = inject(Store);

  @Selector()
  static getState(state: ApplicantsStateModel) {
    return state;
  }

  @Selector()
  static pageIndex(state: ApplicantsStateModel) {
    return state.queryParams.page;
  }

  @Selector()
  static data(state: ApplicantsStateModel) {
    return state.data;
  }

  @Selector()
  static pageSize(state: ApplicantsStateModel) {
    return state.queryParams.pageSize;
  }

  @Selector()
  static pageCount(state: ApplicantsStateModel) {
    return state.pageCount;
  }

  @Selector()
  static totalRecordCount(state: ApplicantsStateModel) {
    return state.totalRecordCount;
  }

  @Selector()
  static loading(state: ApplicantsStateModel) {
    return state.loading;
  }

  @Selector()
  static error(state: ApplicantsStateModel) {
    return state.error;
  }

  @Selector()
  static getQueryParams(state: ApplicantsStateModel) {
    return state.queryParams;
  }

  @Action(UpdateQueryParams)
  updateQueryParams(ctx: StateContext<ApplicantsStateModel>, action: UpdateQueryParams) {
    const state = ctx.getState();
    state.queryParams.page = 1;
    ctx.patchState({
      queryParams: {
        ...state.queryParams,
        ...action.payload,
      },
      data: [],
    });
    ctx.dispatch(new FetchVisaApplicantForms());
  }

  @Action(FetchVisaApplicantForms)
  fetchVisaApplicantForms(ctx: StateContext<ApplicantsStateModel>) {
    this.store.dispatch(new StartLoading());
    const { queryParams } = ctx.getState();
    ctx.patchState({ loading: true, error: null });

    return this.applicantService.getVisaApplicantForms(queryParams).pipe(
      first(),
      tap((response) => {
        ctx.patchState({
          data: response.data,
          queryParams: {
            ...queryParams,
            page: response.pageIndex,
          },
          pageCount: response.pageCount,
          totalRecordCount: response.totalRecordCount,
          loading: false,
          hasMoreData: response.data.length > 0,
        });
      }),
      catchError((error) => {
        ctx.patchState({
          loading: false,
          error: error.error ? error.error.errors[0].description : 'An error occurred, try again later!',
        });
        return of();
      }),
      finalize(() => {
        this.store.dispatch(new StopLoading());
      }),
    );
  }

  @Action(SetPageSize)
  setPageSize(ctx: StateContext<ApplicantsStateModel>, action: SetPageSize) {
    const state = ctx.getState();
    state.queryParams.pageSize = action.pageSize;
    state.queryParams.page = 1;
    ctx.patchState({
      queryParams: state.queryParams,
      data: [],
    });
    ctx.dispatch(new FetchVisaApplicantForms());
  }

  @Action(NextPage)
  nextPage(ctx: StateContext<ApplicantsStateModel>) {
    const state = ctx.getState();
    if (state.queryParams.page < state.pageCount - 1) {
      state.queryParams.page += 1;
      ctx.patchState({
        queryParams: state.queryParams,
        data: [],
      });
      ctx.dispatch(new FetchVisaApplicantForms());
    }
  }

  @Action(PreviousPage)
  previousPage(ctx: StateContext<ApplicantsStateModel>) {
    const state = ctx.getState();
    if (state.queryParams.page > 1) {
      state.queryParams.page -= 1;
      ctx.patchState({
        queryParams: state.queryParams,
        data: [],
      });
      ctx.dispatch(new FetchVisaApplicantForms());
    }
  }

  @Action(GetApplicationStatuses)
  async getApplicationStatuses(ctx: StateContext<ApplicantsStateModel>) {
    const state = ctx.getState();

    ctx.setState({ ...state, isLoadingApplicationStatuses: true });

    return this.applicantService.getApprovalStatus().pipe(
      tap((applicationStatuses) => {
        ctx.dispatch(new GetApplicationStatusesSuccess(applicationStatuses));
      }),
      catchError((error: HttpErrorResponse) => {
        ctx.dispatch(new GetApplicationStatusesError(error.message));
        return of(error);
      }),
    );
  }

  @Action(GetApplicationStatusesSuccess)
  getApplicationStatusesSuccess(ctx: StateContext<ApplicantsStateModel>, action: GetApplicationStatusesSuccess) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      applicationStatuses: action.statuses,
      isLoadingApplicationStatuses: false,
      isGetApplicationStatusesError: null,
    });
  }

  @Action(GetApplicationStatusesError)
  getQuestionTypesError(ctx: StateContext<ApplicantsStateModel>, action: GetApplicationStatusesError) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      isLoadingApplicationStatuses: false,
      isGetApplicationStatusesError: action.error,
    });
  }
}
