import { Component, inject, input } from '@angular/core';
import { MaterialModule } from '../../material.module';
import { CommonModule } from '@angular/common';
import { Store } from '@ngxs/store';
import { LoaderSelectors } from '../../../core/store/loader.selectors';

@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [
    // Modules
    CommonModule,
    MaterialModule,
  ],
  templateUrl: './loader.component.html',
  styleUrl: './loader.component.scss',
  host: {
    '[class.w-full]': 'position() === "absolute"',
    '[class.h-full]': 'position() === "absolute"',
    '[class.min-h-40]': 'position() === "absolute"',
    '[class.flex]': 'position() === "absolute"',
  },
})
export class LoaderComponent {
  position = input<'fixed' | 'absolute'>('absolute');

  store = inject(Store);

  loaderState = this.store.selectSignal(LoaderSelectors.getLoadingState);
}
