@if (alert?.message) {
  <div class="fixed top-0 right-0 p-4 z-20">
    <div class="flex flex-row justify-center items-center gap-x-2 bg-white shadow-lg py-3 px-8">
      @if (alert?.type === 'info') {
        <img src="imgs/info-icon.svg" alt="info" class="w-5 h-5" />
      }
      @if (alert?.type === 'warn') {
        <img src="imgs/warning-icon.svg" alt="warning" class="w-5 h-5" />
      }
      @if (alert?.type === 'error') {
        <img src="imgs/error-icon.svg" alt="error" class="w-5 h-5" />
      }
      @if (alert?.type === 'success') {
        <img src="imgs/success-icon.svg" alt="success" class="w-5 h-5" />
      }
      <span class="text-sm">{{ alert?.message }}</span>
    </div>
  </div>
}
