export const SVG_ICONS: { [key: string]: string } = {
  sort: 'imgs/icons/sort-icon.svg',
  arrow_right: 'imgs/icons/arrow_right.svg',
  chevron_right: 'imgs/icons/chevron-right.svg',
  chevron_left: 'imgs/icons/chevron-left.svg',
  arrow_up: 'imgs/icons/arrow-up.svg',
  error: 'imgs/icons/error.svg',
  drag_vertical: 'imgs/icons/drag-vertical.svg',
  close: 'imgs/icons/close.svg',
  calendar: 'imgs/icons/calendar.svg',
  search: 'imgs/icons/search-icon.svg',
  delete: 'imgs/icons/delete.svg',
  edit: 'imgs/icons/edit.svg',
  copy: 'imgs/icons/copy.svg',
  drag_horizontal: 'imgs/icons/drag-horizontal.svg',
  eye: 'imgs/icons/eye.svg',
  eyes_off: 'imgs/icons/eyes-off.svg',
  info: 'imgs/icons/info.svg',
  check_circle: 'imgs/icons/check-circle.svg',
  reset: 'imgs/icons/reset.svg',
  add: 'imgs/icons/plus-circle.svg',
  title_icon: 'imgs/icons/visa-form.svg',
  link: 'imgs/icons/link.svg',
  checkmark: 'imgs/icons/checkmark.svg',
  user: 'imgs/icons/user.svg',
  file: 'imgs/icons/file.svg',
  photo_thumbnail: 'imgs/icons/photo_thumbnail.svg',
  plus: 'imgs/icons/plus.svg',
  arrow_left: 'imgs/icons/arrow_left.svg',
  history: 'imgs/icons/history.svg',
  logout: 'imgs/icons/logout.svg',
  profile: 'imgs/icons/profile.svg',
  settings: 'imgs/icons/settings.svg',
  applications: 'imgs/icons/applications.svg',
  google: 'imgs/icons/google.svg',
  information_circle: 'imgs/icons/information-circle.svg',
  download: 'imgs/icons/download.svg',
  // add more icons here
};
