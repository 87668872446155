import { IApplicationStatus, IVisaApplicantParams } from '../../models/applicant.interface';

export class UpdateQueryParams {
  static readonly type = '[Applicants] Update Query Params';
  constructor(public payload: Partial<IVisaApplicantParams>) {}
}

export class FetchVisaApplicantForms {
  static readonly type = '[Applicants] Get Visa Applicant Forms';
}

export class SetPageSize {
  static readonly type = '[Applicants] Set Page Size';
  constructor(public pageSize: number) {}
}

export class NextPage {
  static readonly type = '[Applicants] Next Page';
}

export class PreviousPage {
  static readonly type = '[Applicants] Previous Page';
}

export class GetApplicationStatuses {
  static readonly type = '[Applicants] Application response status';
}

export class GetApplicationStatusesSuccess {
  static readonly type = '[Applicants] Application statuses success]';
  constructor(readonly statuses: IApplicationStatus[]) {}
}

export class GetApplicationStatusesError {
  static readonly type = '[Applicants] Application statuses success]';
  constructor(readonly error: string) {}
}
