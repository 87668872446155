<div
  class="opacity-60 bg-white"
  [ngClass]="{
    'w-full h-full min-h-40 absolute inset-0 z-[10] translate-y-[-50%] translate-x-[-50%] top-1/2 left-1/2':
      position() === 'absolute',
    'fixed inset-0 z-20': position() === 'fixed',
  }"></div>

<div class="flex flex-col items-center gap-2 absolute z-20 translate-y-[-50%] translate-x-[-50%] top-1/2 left-1/2">
  <mat-progress-spinner mode="indeterminate" strokeWidth="5" diameter="50" />
  <span class="text-neutral-900">{{ loaderState().message || 'Loading...' }}</span>
</div>
