import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AlertState } from '../../store/alert/alert.state';
import { IAlert } from '../../models/alert.model';
import { CommonModule } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { ClearAlert } from '../../store/alert/alert.actions';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss',
})
export class AlertComponent implements OnInit, OnDestroy {
  alert$!: Observable<IAlert | null>;
  subscription!: Subscription;
  alert: IAlert | null = null;

  constructor(private store: Store) {}

  ngOnInit() {
    this.alert$ = this.store.select(AlertState.getAlert);
    this.subscription = this.alert$.subscribe((alert) => {
      this.alert = alert;
      if (this.alert?.message) {
        setTimeout(() => this.store.dispatch(new ClearAlert()), 5000);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
